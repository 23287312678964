import styled from 'styled-components'

const Small = styled.p`
    font-size: 1.4rem;
    
    a {
        line-height: 28px;
    }
`

export default Small
